var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden",
    },
    [
      _c(
        "v-card",
        { attrs: { width: "350" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { large: "", color: "primary" } },
                [_vm._v("mail")]
              ),
              _vm._v(" Verify your e-mail "),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pt-5" }, [
            _c("p", [
              _vm._v("We have sent an invitation email to your email address"),
            ]),
            _c("p", { staticClass: "text-center secondary--text" }, [
              _vm._v(_vm._s(_vm.email)),
            ]),
            _c("p", [
              _vm._v(
                "Please check your inbox and click on the link in the e-mail to create your account."
              ),
            ]),
            _c("span", { staticClass: "d-block text-center caption" }, [
              _vm._v("(you can close this page)"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }